<script>
import { sitioBus } from "@/main";
import { mapGetters } from "vuex";
export default {
  name: "account-listings-single",

  components: {
    boxStatus: () =>
      import(
        /* webpackChunkName: "listing-box-status" */ "@/components/listing-box-status.vue"
      ),
    boxDetails: () =>
      import(
        /* webpackChunkName: "listing-box-details" */ "@/components/listing-box-details.vue"
      ),
    boxTrades: () =>
      import(
        /* webpackChunkName: "listing-box-trades" */ "@/components/listing-box-trades.vue"
      ),
    boxAmenities: () =>
      import(
        /* webpackChunkName: "listing-box-amenities" */ "@/components/listing-box-amenities.vue"
      ),
    boxDescription: () =>
      import(
        /* webpackChunkName: "listing-box-description" */ "@/components/listing-box-description.vue"
      ),
    boxLocation: () =>
      import(
        /* webpackChunkName: "listing-box-location" */ "@/components/listing-box-location.vue"
      ),
    boxImages: () =>
      import(
        /* webpackChunkName: "listing-box-images" */ "@/components/listing-box-images.vue"
      ),
  },

  props: {
    prefill: {
      type: Object,
    },
  },

  data() {
    return {
      currentEntry: null,
      isEditing: null,
    };
  },

  computed: {
    ...mapGetters("singleListing", ["is_obsolete"]),
    ltg_id() {
      return Number(this.$route.params.ltg_id) || 0;
    },
  },

  created() {
    this.fetchLocal();
    if (!this.currentEntry) {
      this.fetchRemote();
    }
  },

  mounted() {
    sitioBus.$on("update_account_listing", updatedEntry => {
      if (updatedEntry.ltg_id === this.ltg_id) {
        this.currentEntry = null;
        this.currentEntry = updatedEntry;
        this.$emit("entry-updated", updatedEntry);
      }
    });
  },

  beforeDestroy() {
    sitioBus.$off("update_account_listing");
  },

  methods: {
    goBack() {
      if (this.$routerHistory.hasPrevious()) {
        console.log("goBack");
        this.$router.go(-1);
      } else {
        console.log("exit");
        this.exit();
      }
    },
    fetchLocal() {
      if (this.prefill && this.prefill.ltg_id === this.ltg_id) {
        this.currentEntry = this.prefill;
      }
    },
    fetchRemote() {
      this.$store
        .dispatch("singleListing/fetch", this.ltg_id)
        .then(response => {
          this.currentEntry = response;
        })
        .catch(err => {
          console.log(err);
          this.exit();
        });
    },
    exit() {
      // this.$router.go(-1);
      this.$router.push({
        name: "acc-listings",
      });
    },
    deleteConfirm() {
      this.$buefy.dialog.confirm({
        message: "¿Seguro que desea eliminar este anuncio?",
        type: "is-warning",
        confirmText: "Si",
        cancelText: "No",
        onConfirm: () => {
          this.$emit("entry-delete", this.currentEntry.ltg_id);
        },
      });
    },
  },

  watch: {
    is_obsolete(value) {
      if (value) {
        // this.currentEntry = null;
        const loader = this.$buefy.loading.open();
        this.$store
          .dispatch("singleListing/fetch", this.ltg_id)
          .then(response => {
            this.currentEntry = response;
            this.$emit("entry-updated", response);
          })
          .catch(err => {
            console.log(err);
            this.exit();
          })
          .finally(() => {
            loader.close();
          });
      }
    },
    ltg_id() {
      this.currentEntry = null;
      this.$nextTick(() => {
        this.fetchLocal();
        if (!this.currentEntry) {
          this.fetchRemote();
        }
      });
    },
  },
};
</script>

<template>
  <div id="account_listings_single">
    <div class="single__background" @click="exit" />
    <b-loading :active="true" :is-full-page="false" v-if="!currentEntry" />
    <div class="single__body" v-else>
      <nav class="single__nav">
        <div class="nav__container content_width">
          <div class="left">
            <b-button class="close" @click="goBack">
              <b-icon icon="arrow-left" />
            </b-button>
            <!-- <div class="nav__title">Editor</div> -->
            <!-- <div class="nav__title">Editor de anuncio</div> -->
            <div class="nav__title">Anuncio #{{ currentEntry.ltg_id }}</div>
          </div>
          <div class="right">
            <b-button
              class="deleteBTN"
              type="is-danger"
              size="is-small"
              rounded
              outlined
              @click="deleteConfirm"
            >
              Eliminar
            </b-button>
          </div>
        </div>
      </nav>
      <!-- <div class="overview">
        <cardEntry :entry="currentEntry" />
      </div> -->
      <div class="single__content">
        <!-- <div class="banner banner--renew">
          <div>
            <div class="banner__title">Prolonga tu anuncio</div>
            <p class="banner__copy">30 días adicionales por 1 crédito</p>
          </div>
          <button class="button is-primary">
            <b>Añadir</b>
          </button>
        </div> -->
        <boxStatus :entry="currentEntry" />
        <boxImages :entry="currentEntry" />
        <boxDetails :entry="currentEntry" />
        <boxLocation :entry="currentEntry" />
        <boxTrades :entry="currentEntry" />
        <boxAmenities :entry="currentEntry" />
        <boxDescription :entry="currentEntry" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
html[route="acc-listings-single"] {
  // overflow: hidden;
  .app__view {
    position: relative;
  }
  @media (max-width: 63.9375em) {
    #account__bar,
    #account_listings .app__view > *:not(#account_listings_single) {
      display: none;
    }
    // #account_listings_single {
    //   height: 100vh;
    //   min-height: auto;
    //   position: fixed;
    //   top: 0;
    //   overflow-y: auto;
    //   // display: none;
    // }
    #account_listings {
      padding: 0;
    }
    .app__view {
      padding-bottom: unset !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.single__nav {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fff;
  border-bottom: 1px solid #ecf0f4;
  font-size: 1rem;
  min-height: 3.5rem;
  display: flex;
  .nav__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
    > * {
      margin-right: 1rem;
    }
  }
  .close {
    display: flex;
    background-color: transparent;
    border: 0;
    font-size: 0.75rem;
    border-radius: 0;
    border-right: 1px solid #ecf0f4;
    border-left: 1px solid #ecf0f4;
    width: 3rem;
    height: 3rem;
    font-weight: 500;
  }
  .nav__title {
    font-size: 0.875rem;
    font-weight: bold;
  }
  .entry__status {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    .label {
      margin-bottom: 0;
      margin-right: 0.5rem;
    }
  }
}

.single__body {
  background-color: #f6f7f9;
  padding-bottom: 2rem;
  padding-bottom: calc(2rem + var(--mobile-nav-bottom-guard));
}

.single__content {
  max-width: 38em;
  padding-top: 0.5rem;
  margin: 0 auto;
  > * {
    margin: 0 0.5rem;
    margin-bottom: 1rem;
    border-radius: 0.125rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  /deep/ .submit {
    margin-top: 1rem;
  }
}

.banner {
  padding: 1rem;
}

.banner--renew {
  background-color: #5461ff;
  background: linear-gradient(160deg, rgba(84, 97, 255, 1) 0%, #5d2baf 100%);
  color: #fff;
  padding: 1rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .banner__title {
    font-weight: 800;
    font-size: 1.125rem;
  }
  .banner__copy {
    font-size: 0.9375rem;
  }
}

/deep/ .listingBox {
  // margin: 0.75rem 0.5rem;
  // margin-bottom: 1.75rem;
  background-color: #fff;
  // box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 3px 1px;
  box-shadow: rgba(236, 240, 244, 1) 0px 1px 4px 1px;
  // box-shadow: #bfc6ce3b 0px 1px 4px 2px;
  // overflow: hidden;
  .box__title {
    font-size: 0.9375rem;
    color: #0b9696;
    font-weight: bold;
    font-size: 1.125rem;
    color: #000;
  }
  .box__head {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #ecf0f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toggle--collapse {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      // height: 2rem;
      .icon {
        display: none;
        font-size: 0.75rem;
        margin-right: 0.5rem;
      }
    }
  }
  .box__body {
    padding: 1.25rem;
  }
  .message {
    .media-content {
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 0.5rem;
    }
  }
}
@media (max-width: 39.9375em) {
  #account_listings_single {
    min-height: 100vh;
  }
}
@media (min-width: 52.5em) {
  #account_listings_single {
    position: absolute;
    width: 100%;
    // top: 4.5rem;
    top: 0;
    right: 0;
    bottom: 0;
    // height: 100%;
    z-index: 9;
    overflow: hidden;
    overflow-y: auto;
  }
  .single__background {
    background-color: $sitio1;
    opacity: 0.85;
    cursor: pointer;
  }
}
@media (min-width: 64em) {
  #account_listings_single {
    // max-width: 38em;
    margin-left: auto;
    // background-color: #fff;
    // border-left: 1px solid #ecf0f4;
    // box-shadow: -4px 0px 8px #00000012;
    // box-shadow: -4px 0px 8px #2e526b12;
    // box-shadow: -20px 0px 20px 20px #ffffff;
    // box-shadow: -20px 0px 40px 20px #eef0f3;
    box-shadow: 0px 0px 40px 20px #eef0f3;
  }
}
</style>
